
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index3KgBOcAZRpMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/index.vue?macro=true";
import { default as _91id_93oxJpArpr1QMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/bundles/bundle-choices/[id].vue?macro=true";
import { default as indexPw7vT4cnMSMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/bundles/bundle-choices/index.vue?macro=true";
import { default as _91id_934TBfaAmBZ4Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/bundles/bundles/[id].vue?macro=true";
import { default as indexw6a0kbZPD7Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/bundles/bundles/index.vue?macro=true";
import { default as indexK1PBGGsvIWMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/bundles/index.vue?macro=true";
import { default as _91menuCategoryId_934ZpFeHDf9jMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/menus/[id]/categories/[menuCategoryId].vue?macro=true";
import { default as indexHSSrhu5Ue9Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/menus/[id]/index.vue?macro=true";
import { default as indexNltX3wmW5aMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/menus/index.vue?macro=true";
import { default as indexetq5qGkVnAMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/modifiers/index.vue?macro=true";
import { default as indexr9N8JYg23AMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/modifiers/modifier-groups/[id]/index.vue?macro=true";
import { default as _91modifierId_93co37WEwphxMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue?macro=true";
import { default as _91id_93Hp3J2MFuYEMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/modifiers/modifier-groups/[id].vue?macro=true";
import { default as indexAEvXciSW7YMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/modifiers/modifier-groups/index.vue?macro=true";
import { default as index9Ookxu4iAPMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/products/index.vue?macro=true";
import { default as _91id_93f2KzgWmX6bMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/products/product-categories/[id].vue?macro=true";
import { default as indexQ0Pgb3UHFQMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/products/product-categories/index.vue?macro=true";
import { default as _91id_93zKPkryRwngMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/products/products/[id].vue?macro=true";
import { default as indexYwRY9GcYVZMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/products/products/index.vue?macro=true";
import { default as merchantsds5UpsfJ9aMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/merchants.vue?macro=true";
import { default as _91accountId_93pgj5zlC0WAMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/accounts/[id]/accounts/[accountId].vue?macro=true";
import { default as indexnCdwgUwDEQMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/accounts/[id]/index.vue?macro=true";
import { default as _91id_93KN4shRjPqJMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/accounts/[id].vue?macro=true";
import { default as indexEyWi3pvcTAMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/accounts/index.vue?macro=true";
import { default as credentialsoHAacNfs47Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/connections/[id]/credentials.vue?macro=true";
import { default as indexcEjq4A4PfWMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/connections/[id]/index.vue?macro=true";
import { default as _91id_93H5augCNx5sMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/connections/[id].vue?macro=true";
import { default as _91driver_93LI4rfU1AHZMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/connections/create/[driver].vue?macro=true";
import { default as indexTcrXagOS0rMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/connections/create/index.vue?macro=true";
import { default as indexzb3j9HRIf9Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/connections/index.vue?macro=true";
import { default as index65cJDqNIpqMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/customer-sites/[id]/index.vue?macro=true";
import { default as payment_45methodsjK1iMWNQwPMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/customer-sites/[id]/payment-methods.vue?macro=true";
import { default as _91id_93U1Djl2FyaNMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/customer-sites/[id].vue?macro=true";
import { default as indexBBbwLNJlaLMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/customer-sites/index.vue?macro=true";
import { default as _91id_93wYKvYTxbUeMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/discounts/discounts/[id].vue?macro=true";
import { default as indexLPTuBMg8lFMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/discounts/discounts/index.vue?macro=true";
import { default as indexK9Z3E3VsXrMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/discounts/index.vue?macro=true";
import { default as _91id_93KPzbKZmulVMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/employees/[id].vue?macro=true";
import { default as indexrT78nrb7ikMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/employees/index.vue?macro=true";
import { default as indexcph05L1BlGMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/images/index.vue?macro=true";
import { default as indexJKkeuRiILCMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/kitchens/index.vue?macro=true";
import { default as modifier_45groupsyXoSTLDoLeMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/kitchens/modifier-groups.vue?macro=true";
import { default as kitchensbASRJukIzIMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/kitchens.vue?macro=true";
import { default as indexad8zoAShKJMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/stock/index.vue?macro=true";
import { default as modifier_45recipesBchKHb3eCrMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/stock/modifier-recipes.vue?macro=true";
import { default as variant_45recipesiHGxFIfIoVMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/stock/variant-recipes.vue?macro=true";
import { default as stock0feBprfkSwMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/stock.vue?macro=true";
import { default as bookkeepingZ77sNJOKnNMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/stores/[id]/bookkeeping.vue?macro=true";
import { default as indexyM4dpmVtbZMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/stores/[id]/index.vue?macro=true";
import { default as kitchenCqhMQ4WQJwMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/stores/[id]/kitchen.vue?macro=true";
import { default as stockmh5B74UMnRMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/stores/[id]/stock.vue?macro=true";
import { default as _91id_93pxynWM0e4lMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/stores/[id].vue?macro=true";
import { default as indexFOqTRH3KSeMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/stores/index.vue?macro=true";
import { default as indexF5cVoi8SWrMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/terminals/colors/index.vue?macro=true";
import { default as _91id_93s0wyXMhL5oMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/terminals/folders/[id].vue?macro=true";
import { default as indexfShMQKFhJ5Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/terminals/folders/index.vue?macro=true";
import { default as channel_45settlementsPcpcMLUF4JMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/terminals/terminals/[id]/channel-settlements.vue?macro=true";
import { default as indexCKjwRYCLizMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/terminals/terminals/[id]/index.vue?macro=true";
import { default as _91id_93g4BKnmiH6LMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/terminals/terminals/[id].vue?macro=true";
import { default as indexeRa7fFejZaMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/terminals/terminals/index.vue?macro=true";
import { default as index4eQjbaYRAyMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/profile/index.vue?macro=true";
import { default as cancellationsLheReQKzyhMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/reports/cancellations.vue?macro=true";
import { default as cash_45transfers0EoGRu5wF3Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/reports/cash-transfers.vue?macro=true";
import { default as closing_45errors7uOfMIfdpKMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/reports/closing-errors.vue?macro=true";
import { default as downloadVaKBp2Pq2mMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/reports/download.vue?macro=true";
import { default as efficiencyuyZGTmIsuVMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/reports/efficiency.vue?macro=true";
import { default as indexP2hJMZE1nJMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/reports/index.vue?macro=true";
import { default as payments9C6Im437qnMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/reports/payments.vue?macro=true";
import { default as productionkR1BdcWQLtMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/reports/production.vue?macro=true";
import { default as reservationshXAO4sLr4CMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/reports/reservations.vue?macro=true";
import { default as reviewsKthjzRLDSpMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/reports/reviews.vue?macro=true";
import { default as tipsfJongBlzi1Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/reports/tips.vue?macro=true";
import { default as accountsBT5iDOf5iWMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settings/accounts.vue?macro=true";
import { default as financesIeZUHwBso6Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settings/finances.vue?macro=true";
import { default as index1bNv0YXge6Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settings/index.vue?macro=true";
import { default as integrationsIbBqId7d8bMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settings/integrations.vue?macro=true";
import { default as operationssDXSqlS4mLMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settings/operations.vue?macro=true";
import { default as settingsAyz2HbBJyLMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settings.vue?macro=true";
import { default as indexMRoHwN8uyAMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/channel-settlements/[id]/index.vue?macro=true";
import { default as logii9dMrwzt6Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/channel-settlements/[id]/log.vue?macro=true";
import { default as ordersJry8GwFcLKMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/channel-settlements/[id]/orders.vue?macro=true";
import { default as _91channelSettlementSnapshotId_93J1sjU8La7rMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue?macro=true";
import { default as indexmvKQB89AatMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/channel-settlements/[id]/snapshots/index.vue?macro=true";
import { default as _91id_93LVxt1AF1ZsMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/channel-settlements/[id].vue?macro=true";
import { default as _91id_93yamUY7pQLtMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/orders/[id].vue?macro=true";
import { default as indexTWu1P2haGeMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/orders/index.vue?macro=true";
import { default as bookkeepingPmmjtVRaFQMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/store-settlements/[id]/bookkeeping.vue?macro=true";
import { default as channel_45settlementsFSKdCaxmP8Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/store-settlements/[id]/channel-settlements.vue?macro=true";
import { default as indexzQ0D3ZVVnwMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/store-settlements/[id]/index.vue?macro=true";
import { default as logmcejrA6KJ4Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/store-settlements/[id]/log.vue?macro=true";
import { default as orderswVOKYEzIIXMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/store-settlements/[id]/orders.vue?macro=true";
import { default as _91reconciliationId_93bZfWA1a1paMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/store-settlements/[id]/reconciliations/[reconciliationId].vue?macro=true";
import { default as indexto0cmwuAAzMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/store-settlements/[id]/reconciliations/index.vue?macro=true";
import { default as _91id_93hugnt0aVqBMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/store-settlements/[id].vue?macro=true";
import { default as indexXIiDTHr3YiMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/store-settlements/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/index.vue")
  },
  {
    name: "inventory-bundles-bundle-choices-id",
    path: "/inventory/bundles/bundle-choices/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/bundles/bundle-choices/[id].vue")
  },
  {
    name: "inventory-bundles-bundle-choices",
    path: "/inventory/bundles/bundle-choices",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/bundles/bundle-choices/index.vue")
  },
  {
    name: "inventory-bundles-bundles-id",
    path: "/inventory/bundles/bundles/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/bundles/bundles/[id].vue")
  },
  {
    name: "inventory-bundles-bundles",
    path: "/inventory/bundles/bundles",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/bundles/bundles/index.vue")
  },
  {
    name: "inventory-bundles",
    path: "/inventory/bundles",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/bundles/index.vue")
  },
  {
    name: "inventory-menus-id-categories-menuCategoryId",
    path: "/inventory/menus/:id()/categories/:menuCategoryId()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/menus/[id]/categories/[menuCategoryId].vue")
  },
  {
    name: "inventory-menus-id",
    path: "/inventory/menus/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/menus/[id]/index.vue")
  },
  {
    name: "inventory-menus",
    path: "/inventory/menus",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/menus/index.vue")
  },
  {
    name: "inventory-modifiers",
    path: "/inventory/modifiers",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/modifiers/index.vue")
  },
  {
    name: _91id_93Hp3J2MFuYEMeta?.name,
    path: "/inventory/modifiers/modifier-groups/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/modifiers/modifier-groups/[id].vue"),
    children: [
  {
    name: "inventory-modifiers-modifier-groups-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/modifiers/modifier-groups/[id]/index.vue")
  },
  {
    name: "inventory-modifiers-modifier-groups-id-modifiers-modifierId",
    path: "modifiers/:modifierId()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue")
  }
]
  },
  {
    name: "inventory-modifiers-modifier-groups",
    path: "/inventory/modifiers/modifier-groups",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/modifiers/modifier-groups/index.vue")
  },
  {
    name: "inventory-products",
    path: "/inventory/products",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/products/index.vue")
  },
  {
    name: "inventory-products-product-categories-id",
    path: "/inventory/products/product-categories/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/products/product-categories/[id].vue")
  },
  {
    name: "inventory-products-product-categories",
    path: "/inventory/products/product-categories",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/products/product-categories/index.vue")
  },
  {
    name: "inventory-products-products-id",
    path: "/inventory/products/products/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/products/products/[id].vue")
  },
  {
    name: "inventory-products-products",
    path: "/inventory/products/products",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/inventory/products/products/index.vue")
  },
  {
    name: "merchants",
    path: "/merchants",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/merchants.vue")
  },
  {
    name: _91id_93KN4shRjPqJMeta?.name,
    path: "/point-of-sale/accounts/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/accounts/[id].vue"),
    children: [
  {
    name: "point-of-sale-accounts-id-accounts-accountId",
    path: "accounts/:accountId()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/accounts/[id]/accounts/[accountId].vue")
  },
  {
    name: "point-of-sale-accounts-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/accounts/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-accounts",
    path: "/point-of-sale/accounts",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/accounts/index.vue")
  },
  {
    name: _91id_93H5augCNx5sMeta?.name,
    path: "/point-of-sale/connections/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/connections/[id].vue"),
    children: [
  {
    name: "point-of-sale-connections-id-credentials",
    path: "credentials",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/connections/[id]/credentials.vue")
  },
  {
    name: "point-of-sale-connections-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/connections/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-connections-create-driver",
    path: "/point-of-sale/connections/create/:driver()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/connections/create/[driver].vue")
  },
  {
    name: "point-of-sale-connections-create",
    path: "/point-of-sale/connections/create",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/connections/create/index.vue")
  },
  {
    name: "point-of-sale-connections",
    path: "/point-of-sale/connections",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/connections/index.vue")
  },
  {
    name: _91id_93U1Djl2FyaNMeta?.name,
    path: "/point-of-sale/customer-sites/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/customer-sites/[id].vue"),
    children: [
  {
    name: "point-of-sale-customer-sites-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/customer-sites/[id]/index.vue")
  },
  {
    name: "point-of-sale-customer-sites-id-payment-methods",
    path: "payment-methods",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/customer-sites/[id]/payment-methods.vue")
  }
]
  },
  {
    name: "point-of-sale-customer-sites",
    path: "/point-of-sale/customer-sites",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/customer-sites/index.vue")
  },
  {
    name: "point-of-sale-discounts-discounts-id",
    path: "/point-of-sale/discounts/discounts/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/discounts/discounts/[id].vue")
  },
  {
    name: "point-of-sale-discounts-discounts",
    path: "/point-of-sale/discounts/discounts",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/discounts/discounts/index.vue")
  },
  {
    name: "point-of-sale-discounts",
    path: "/point-of-sale/discounts",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/discounts/index.vue")
  },
  {
    name: "point-of-sale-employees-id",
    path: "/point-of-sale/employees/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/employees/[id].vue")
  },
  {
    name: "point-of-sale-employees",
    path: "/point-of-sale/employees",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/employees/index.vue")
  },
  {
    name: "point-of-sale-images",
    path: "/point-of-sale/images",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/images/index.vue")
  },
  {
    name: kitchensbASRJukIzIMeta?.name,
    path: "/point-of-sale/kitchens",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/kitchens.vue"),
    children: [
  {
    name: "point-of-sale-kitchens",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/kitchens/index.vue")
  },
  {
    name: "point-of-sale-kitchens-modifier-groups",
    path: "modifier-groups",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/kitchens/modifier-groups.vue")
  }
]
  },
  {
    name: stock0feBprfkSwMeta?.name,
    path: "/point-of-sale/stock",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/stock.vue"),
    children: [
  {
    name: "point-of-sale-stock",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/stock/index.vue")
  },
  {
    name: "point-of-sale-stock-modifier-recipes",
    path: "modifier-recipes",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/stock/modifier-recipes.vue")
  },
  {
    name: "point-of-sale-stock-variant-recipes",
    path: "variant-recipes",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/stock/variant-recipes.vue")
  }
]
  },
  {
    name: _91id_93pxynWM0e4lMeta?.name,
    path: "/point-of-sale/stores/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/stores/[id].vue"),
    children: [
  {
    name: "point-of-sale-stores-id-bookkeeping",
    path: "bookkeeping",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/stores/[id]/bookkeeping.vue")
  },
  {
    name: "point-of-sale-stores-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/stores/[id]/index.vue")
  },
  {
    name: "point-of-sale-stores-id-kitchen",
    path: "kitchen",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/stores/[id]/kitchen.vue")
  },
  {
    name: "point-of-sale-stores-id-stock",
    path: "stock",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/stores/[id]/stock.vue")
  }
]
  },
  {
    name: "point-of-sale-stores",
    path: "/point-of-sale/stores",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/stores/index.vue")
  },
  {
    name: "point-of-sale-terminals-colors",
    path: "/point-of-sale/terminals/colors",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/terminals/colors/index.vue")
  },
  {
    name: "point-of-sale-terminals-folders-id",
    path: "/point-of-sale/terminals/folders/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/terminals/folders/[id].vue")
  },
  {
    name: "point-of-sale-terminals-folders",
    path: "/point-of-sale/terminals/folders",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/terminals/folders/index.vue")
  },
  {
    name: _91id_93g4BKnmiH6LMeta?.name,
    path: "/point-of-sale/terminals/terminals/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/terminals/terminals/[id].vue"),
    children: [
  {
    name: "point-of-sale-terminals-terminals-id-channel-settlements",
    path: "channel-settlements",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/terminals/terminals/[id]/channel-settlements.vue")
  },
  {
    name: "point-of-sale-terminals-terminals-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/terminals/terminals/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-terminals-terminals",
    path: "/point-of-sale/terminals/terminals",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/point-of-sale/terminals/terminals/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/profile/index.vue")
  },
  {
    name: "reports-cancellations",
    path: "/reports/cancellations",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/reports/cancellations.vue")
  },
  {
    name: "reports-cash-transfers",
    path: "/reports/cash-transfers",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/reports/cash-transfers.vue")
  },
  {
    name: "reports-closing-errors",
    path: "/reports/closing-errors",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/reports/closing-errors.vue")
  },
  {
    name: "reports-download",
    path: "/reports/download",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/reports/download.vue")
  },
  {
    name: "reports-efficiency",
    path: "/reports/efficiency",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/reports/efficiency.vue")
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/reports/index.vue")
  },
  {
    name: "reports-payments",
    path: "/reports/payments",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/reports/payments.vue")
  },
  {
    name: "reports-production",
    path: "/reports/production",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/reports/production.vue")
  },
  {
    name: "reports-reservations",
    path: "/reports/reservations",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/reports/reservations.vue")
  },
  {
    name: "reports-reviews",
    path: "/reports/reviews",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/reports/reviews.vue")
  },
  {
    name: "reports-tips",
    path: "/reports/tips",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/reports/tips.vue")
  },
  {
    name: settingsAyz2HbBJyLMeta?.name,
    path: "/settings",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settings.vue"),
    children: [
  {
    name: "settings-accounts",
    path: "accounts",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settings/accounts.vue")
  },
  {
    name: "settings-finances",
    path: "finances",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settings/finances.vue")
  },
  {
    name: "settings",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settings/index.vue")
  },
  {
    name: "settings-integrations",
    path: "integrations",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settings/integrations.vue")
  },
  {
    name: "settings-operations",
    path: "operations",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settings/operations.vue")
  }
]
  },
  {
    name: _91id_93LVxt1AF1ZsMeta?.name,
    path: "/settlement/channel-settlements/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/channel-settlements/[id].vue"),
    children: [
  {
    name: "settlement-channel-settlements-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/channel-settlements/[id]/index.vue")
  },
  {
    name: "settlement-channel-settlements-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/channel-settlements/[id]/log.vue")
  },
  {
    name: "settlement-channel-settlements-id-orders",
    path: "orders",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/channel-settlements/[id]/orders.vue")
  },
  {
    name: "settlement-channel-settlements-id-snapshots-channelSettlementSnapshotId",
    path: "snapshots/:channelSettlementSnapshotId()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue")
  },
  {
    name: "settlement-channel-settlements-id-snapshots",
    path: "snapshots",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/channel-settlements/[id]/snapshots/index.vue")
  }
]
  },
  {
    name: "settlement-orders-id",
    path: "/settlement/orders/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/orders/[id].vue")
  },
  {
    name: "settlement-orders",
    path: "/settlement/orders",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/orders/index.vue")
  },
  {
    name: _91id_93hugnt0aVqBMeta?.name,
    path: "/settlement/store-settlements/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/store-settlements/[id].vue"),
    children: [
  {
    name: "settlement-store-settlements-id-bookkeeping",
    path: "bookkeeping",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/store-settlements/[id]/bookkeeping.vue")
  },
  {
    name: "settlement-store-settlements-id-channel-settlements",
    path: "channel-settlements",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/store-settlements/[id]/channel-settlements.vue")
  },
  {
    name: "settlement-store-settlements-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/store-settlements/[id]/index.vue")
  },
  {
    name: "settlement-store-settlements-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/store-settlements/[id]/log.vue")
  },
  {
    name: "settlement-store-settlements-id-orders",
    path: "orders",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/store-settlements/[id]/orders.vue")
  },
  {
    name: "settlement-store-settlements-id-reconciliations-reconciliationId",
    path: "reconciliations/:reconciliationId()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/store-settlements/[id]/reconciliations/[reconciliationId].vue")
  },
  {
    name: "settlement-store-settlements-id-reconciliations",
    path: "reconciliations",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/store-settlements/[id]/reconciliations/index.vue")
  }
]
  },
  {
    name: "settlement-store-settlements",
    path: "/settlement/store-settlements",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250126214545/pages/settlement/store-settlements/index.vue")
  }
]