
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexf67K8Phv2qMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/index.vue?macro=true";
import { default as _91id_93AbRu0Ma5nQMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/bundles/bundle-choices/[id].vue?macro=true";
import { default as indexS1ackbWFcCMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/bundles/bundle-choices/index.vue?macro=true";
import { default as _91id_93yzE2s7Pp4CMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/bundles/bundles/[id].vue?macro=true";
import { default as indexMYsGvpSCHkMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/bundles/bundles/index.vue?macro=true";
import { default as indexr6q3lWqpUWMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/bundles/index.vue?macro=true";
import { default as _91menuCategoryId_93kHDETjwuA8Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/menus/[id]/categories/[menuCategoryId].vue?macro=true";
import { default as index2ozAUGRz4IMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/menus/[id]/index.vue?macro=true";
import { default as index1v92BiHLTGMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/menus/index.vue?macro=true";
import { default as indexUL7KMfsRPdMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/modifiers/index.vue?macro=true";
import { default as indexDSlaDdRPtgMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/modifiers/modifier-groups/[id]/index.vue?macro=true";
import { default as _91modifierId_93KErqJ8wa5sMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue?macro=true";
import { default as _91id_93qIQqtrmfwRMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/modifiers/modifier-groups/[id].vue?macro=true";
import { default as indexAhPWLPOO5UMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/modifiers/modifier-groups/index.vue?macro=true";
import { default as index9s0cTomc0XMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/products/index.vue?macro=true";
import { default as _91id_93LrTqZydMWLMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/products/product-categories/[id].vue?macro=true";
import { default as index7SrL1RLhvPMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/products/product-categories/index.vue?macro=true";
import { default as _91id_934tnXVQNyoBMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/products/products/[id].vue?macro=true";
import { default as indexOwk30WRcYaMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/products/products/index.vue?macro=true";
import { default as merchants5sk3KqOxSMMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/merchants.vue?macro=true";
import { default as _91accountId_939rilmi9ywRMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/accounts/[id]/accounts/[accountId].vue?macro=true";
import { default as indexyv9zpNID9oMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/accounts/[id]/index.vue?macro=true";
import { default as _91id_93M5UTJDukXoMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/accounts/[id].vue?macro=true";
import { default as indexLRxu3DU4pKMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/accounts/index.vue?macro=true";
import { default as credentials5024FgCQX4Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/connections/[id]/credentials.vue?macro=true";
import { default as indexM0UnFQ78emMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/connections/[id]/index.vue?macro=true";
import { default as _91id_93zk7iqPl02yMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/connections/[id].vue?macro=true";
import { default as _91driver_939aaVCJ4NmjMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/connections/create/[driver].vue?macro=true";
import { default as indexwe9wEUhxBiMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/connections/create/index.vue?macro=true";
import { default as indexhEEiyd6AXeMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/connections/index.vue?macro=true";
import { default as indexS9k4YUCoP0Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/customer-sites/[id]/index.vue?macro=true";
import { default as payment_45methodsFjxWhmQNHUMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/customer-sites/[id]/payment-methods.vue?macro=true";
import { default as _91id_93R8h9maplB2Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/customer-sites/[id].vue?macro=true";
import { default as indexW5Im0Q8ykbMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/customer-sites/index.vue?macro=true";
import { default as _91id_93rddkCwnblTMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/discounts/discounts/[id].vue?macro=true";
import { default as indexxC2thoQE2sMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/discounts/discounts/index.vue?macro=true";
import { default as index6rxcWge93QMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/discounts/index.vue?macro=true";
import { default as _91id_93vpXxv3eTcBMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/employees/[id].vue?macro=true";
import { default as index74WmCZcwzhMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/employees/index.vue?macro=true";
import { default as indexAUTFz8yRcWMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/images/index.vue?macro=true";
import { default as indexggAVcXgNPYMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/kitchens/index.vue?macro=true";
import { default as modifier_45groupsvlsowTGzYyMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/kitchens/modifier-groups.vue?macro=true";
import { default as kitchens99de3vEn1jMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/kitchens.vue?macro=true";
import { default as index9G8ApOhIIkMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/stock/index.vue?macro=true";
import { default as modifier_45recipes268tPd5j8DMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/stock/modifier-recipes.vue?macro=true";
import { default as variant_45recipes64nDdCjuEwMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/stock/variant-recipes.vue?macro=true";
import { default as stockWKJFv1SgSvMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/stock.vue?macro=true";
import { default as bookkeepingPjd2s7FzexMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/stores/[id]/bookkeeping.vue?macro=true";
import { default as indexgVyGKuMAb6Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/stores/[id]/index.vue?macro=true";
import { default as kitchenwTxl9YwnmwMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/stores/[id]/kitchen.vue?macro=true";
import { default as stockadnNFMA9EWMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/stores/[id]/stock.vue?macro=true";
import { default as _91id_93WAjbvo1KQlMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/stores/[id].vue?macro=true";
import { default as index9pgMucQIMnMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/stores/index.vue?macro=true";
import { default as indexgh1BYepJt0Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/terminals/colors/index.vue?macro=true";
import { default as _91id_93QStIofZk3nMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/terminals/folders/[id].vue?macro=true";
import { default as indexLN0TV3e6LOMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/terminals/folders/index.vue?macro=true";
import { default as channel_45settlements9BGOvJGG6pMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/terminals/terminals/[id]/channel-settlements.vue?macro=true";
import { default as indexfcdYYKS1UmMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/terminals/terminals/[id]/index.vue?macro=true";
import { default as _91id_93tEVMxU2kZBMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/terminals/terminals/[id].vue?macro=true";
import { default as indexgqAJKSEDFEMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/terminals/terminals/index.vue?macro=true";
import { default as index3d1gLpM1X4Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/profile/index.vue?macro=true";
import { default as cancellationsWVIolJC9b0Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/reports/cancellations.vue?macro=true";
import { default as cash_45transfers9bIMAE0C6dMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/reports/cash-transfers.vue?macro=true";
import { default as closing_45errorsz6iFyjsIP9Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/reports/closing-errors.vue?macro=true";
import { default as downloadfFLvyztgybMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/reports/download.vue?macro=true";
import { default as efficiencyTN94PfLASpMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/reports/efficiency.vue?macro=true";
import { default as indexAasuvMhXwiMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/reports/index.vue?macro=true";
import { default as paymentseJt38KBaUQMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/reports/payments.vue?macro=true";
import { default as productionBQ6rvZFAp7Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/reports/production.vue?macro=true";
import { default as reservationsJIE2QYXuUPMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/reports/reservations.vue?macro=true";
import { default as reviews8XeVXa5ba8Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/reports/reviews.vue?macro=true";
import { default as tipsPyJG5Qq55GMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/reports/tips.vue?macro=true";
import { default as accounts8LOdN1e7f2Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settings/accounts.vue?macro=true";
import { default as financesA06i9jRQh5Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settings/finances.vue?macro=true";
import { default as indexSFppIQ0UIwMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settings/index.vue?macro=true";
import { default as integrationsDeNV3qMotFMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settings/integrations.vue?macro=true";
import { default as operationsJBirLDMQdoMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settings/operations.vue?macro=true";
import { default as settings3hP7GC8sssMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settings.vue?macro=true";
import { default as indexykJopvHCPSMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/channel-settlements/[id]/index.vue?macro=true";
import { default as logMAtvHPpvodMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/channel-settlements/[id]/log.vue?macro=true";
import { default as ordersrHb1rUYeCiMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/channel-settlements/[id]/orders.vue?macro=true";
import { default as _91channelSettlementSnapshotId_93uIPgXs50GzMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue?macro=true";
import { default as indexo5lahPTB3EMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/channel-settlements/[id]/snapshots/index.vue?macro=true";
import { default as _91id_93uvGUWLOXrYMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/channel-settlements/[id].vue?macro=true";
import { default as _91id_939ItgGG7r3zMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/orders/[id].vue?macro=true";
import { default as index9lhROo1hzNMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/orders/index.vue?macro=true";
import { default as bookkeeping6hIZyt0IkvMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/store-settlements/[id]/bookkeeping.vue?macro=true";
import { default as channel_45settlementsOLx7MTtKkDMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/store-settlements/[id]/channel-settlements.vue?macro=true";
import { default as indexCtwSDDX8itMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/store-settlements/[id]/index.vue?macro=true";
import { default as logpFQBvJQ4YDMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/store-settlements/[id]/log.vue?macro=true";
import { default as ordersNre2cpFTo1Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/store-settlements/[id]/orders.vue?macro=true";
import { default as _91reconciliationId_93TIvkqB8dvbMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/store-settlements/[id]/reconciliations/[reconciliationId].vue?macro=true";
import { default as indexKRTt7PByreMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/store-settlements/[id]/reconciliations/index.vue?macro=true";
import { default as _91id_93ANc41ai5d4Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/store-settlements/[id].vue?macro=true";
import { default as indexMlDvweQZW0Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/store-settlements/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/index.vue")
  },
  {
    name: "inventory-bundles-bundle-choices-id",
    path: "/inventory/bundles/bundle-choices/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/bundles/bundle-choices/[id].vue")
  },
  {
    name: "inventory-bundles-bundle-choices",
    path: "/inventory/bundles/bundle-choices",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/bundles/bundle-choices/index.vue")
  },
  {
    name: "inventory-bundles-bundles-id",
    path: "/inventory/bundles/bundles/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/bundles/bundles/[id].vue")
  },
  {
    name: "inventory-bundles-bundles",
    path: "/inventory/bundles/bundles",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/bundles/bundles/index.vue")
  },
  {
    name: "inventory-bundles",
    path: "/inventory/bundles",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/bundles/index.vue")
  },
  {
    name: "inventory-menus-id-categories-menuCategoryId",
    path: "/inventory/menus/:id()/categories/:menuCategoryId()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/menus/[id]/categories/[menuCategoryId].vue")
  },
  {
    name: "inventory-menus-id",
    path: "/inventory/menus/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/menus/[id]/index.vue")
  },
  {
    name: "inventory-menus",
    path: "/inventory/menus",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/menus/index.vue")
  },
  {
    name: "inventory-modifiers",
    path: "/inventory/modifiers",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/modifiers/index.vue")
  },
  {
    name: _91id_93qIQqtrmfwRMeta?.name,
    path: "/inventory/modifiers/modifier-groups/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/modifiers/modifier-groups/[id].vue"),
    children: [
  {
    name: "inventory-modifiers-modifier-groups-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/modifiers/modifier-groups/[id]/index.vue")
  },
  {
    name: "inventory-modifiers-modifier-groups-id-modifiers-modifierId",
    path: "modifiers/:modifierId()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue")
  }
]
  },
  {
    name: "inventory-modifiers-modifier-groups",
    path: "/inventory/modifiers/modifier-groups",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/modifiers/modifier-groups/index.vue")
  },
  {
    name: "inventory-products",
    path: "/inventory/products",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/products/index.vue")
  },
  {
    name: "inventory-products-product-categories-id",
    path: "/inventory/products/product-categories/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/products/product-categories/[id].vue")
  },
  {
    name: "inventory-products-product-categories",
    path: "/inventory/products/product-categories",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/products/product-categories/index.vue")
  },
  {
    name: "inventory-products-products-id",
    path: "/inventory/products/products/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/products/products/[id].vue")
  },
  {
    name: "inventory-products-products",
    path: "/inventory/products/products",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/inventory/products/products/index.vue")
  },
  {
    name: "merchants",
    path: "/merchants",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/merchants.vue")
  },
  {
    name: _91id_93M5UTJDukXoMeta?.name,
    path: "/point-of-sale/accounts/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/accounts/[id].vue"),
    children: [
  {
    name: "point-of-sale-accounts-id-accounts-accountId",
    path: "accounts/:accountId()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/accounts/[id]/accounts/[accountId].vue")
  },
  {
    name: "point-of-sale-accounts-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/accounts/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-accounts",
    path: "/point-of-sale/accounts",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/accounts/index.vue")
  },
  {
    name: _91id_93zk7iqPl02yMeta?.name,
    path: "/point-of-sale/connections/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/connections/[id].vue"),
    children: [
  {
    name: "point-of-sale-connections-id-credentials",
    path: "credentials",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/connections/[id]/credentials.vue")
  },
  {
    name: "point-of-sale-connections-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/connections/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-connections-create-driver",
    path: "/point-of-sale/connections/create/:driver()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/connections/create/[driver].vue")
  },
  {
    name: "point-of-sale-connections-create",
    path: "/point-of-sale/connections/create",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/connections/create/index.vue")
  },
  {
    name: "point-of-sale-connections",
    path: "/point-of-sale/connections",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/connections/index.vue")
  },
  {
    name: _91id_93R8h9maplB2Meta?.name,
    path: "/point-of-sale/customer-sites/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/customer-sites/[id].vue"),
    children: [
  {
    name: "point-of-sale-customer-sites-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/customer-sites/[id]/index.vue")
  },
  {
    name: "point-of-sale-customer-sites-id-payment-methods",
    path: "payment-methods",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/customer-sites/[id]/payment-methods.vue")
  }
]
  },
  {
    name: "point-of-sale-customer-sites",
    path: "/point-of-sale/customer-sites",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/customer-sites/index.vue")
  },
  {
    name: "point-of-sale-discounts-discounts-id",
    path: "/point-of-sale/discounts/discounts/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/discounts/discounts/[id].vue")
  },
  {
    name: "point-of-sale-discounts-discounts",
    path: "/point-of-sale/discounts/discounts",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/discounts/discounts/index.vue")
  },
  {
    name: "point-of-sale-discounts",
    path: "/point-of-sale/discounts",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/discounts/index.vue")
  },
  {
    name: "point-of-sale-employees-id",
    path: "/point-of-sale/employees/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/employees/[id].vue")
  },
  {
    name: "point-of-sale-employees",
    path: "/point-of-sale/employees",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/employees/index.vue")
  },
  {
    name: "point-of-sale-images",
    path: "/point-of-sale/images",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/images/index.vue")
  },
  {
    name: kitchens99de3vEn1jMeta?.name,
    path: "/point-of-sale/kitchens",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/kitchens.vue"),
    children: [
  {
    name: "point-of-sale-kitchens",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/kitchens/index.vue")
  },
  {
    name: "point-of-sale-kitchens-modifier-groups",
    path: "modifier-groups",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/kitchens/modifier-groups.vue")
  }
]
  },
  {
    name: stockWKJFv1SgSvMeta?.name,
    path: "/point-of-sale/stock",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/stock.vue"),
    children: [
  {
    name: "point-of-sale-stock",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/stock/index.vue")
  },
  {
    name: "point-of-sale-stock-modifier-recipes",
    path: "modifier-recipes",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/stock/modifier-recipes.vue")
  },
  {
    name: "point-of-sale-stock-variant-recipes",
    path: "variant-recipes",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/stock/variant-recipes.vue")
  }
]
  },
  {
    name: _91id_93WAjbvo1KQlMeta?.name,
    path: "/point-of-sale/stores/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/stores/[id].vue"),
    children: [
  {
    name: "point-of-sale-stores-id-bookkeeping",
    path: "bookkeeping",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/stores/[id]/bookkeeping.vue")
  },
  {
    name: "point-of-sale-stores-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/stores/[id]/index.vue")
  },
  {
    name: "point-of-sale-stores-id-kitchen",
    path: "kitchen",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/stores/[id]/kitchen.vue")
  },
  {
    name: "point-of-sale-stores-id-stock",
    path: "stock",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/stores/[id]/stock.vue")
  }
]
  },
  {
    name: "point-of-sale-stores",
    path: "/point-of-sale/stores",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/stores/index.vue")
  },
  {
    name: "point-of-sale-terminals-colors",
    path: "/point-of-sale/terminals/colors",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/terminals/colors/index.vue")
  },
  {
    name: "point-of-sale-terminals-folders-id",
    path: "/point-of-sale/terminals/folders/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/terminals/folders/[id].vue")
  },
  {
    name: "point-of-sale-terminals-folders",
    path: "/point-of-sale/terminals/folders",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/terminals/folders/index.vue")
  },
  {
    name: _91id_93tEVMxU2kZBMeta?.name,
    path: "/point-of-sale/terminals/terminals/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/terminals/terminals/[id].vue"),
    children: [
  {
    name: "point-of-sale-terminals-terminals-id-channel-settlements",
    path: "channel-settlements",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/terminals/terminals/[id]/channel-settlements.vue")
  },
  {
    name: "point-of-sale-terminals-terminals-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/terminals/terminals/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-terminals-terminals",
    path: "/point-of-sale/terminals/terminals",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/point-of-sale/terminals/terminals/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/profile/index.vue")
  },
  {
    name: "reports-cancellations",
    path: "/reports/cancellations",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/reports/cancellations.vue")
  },
  {
    name: "reports-cash-transfers",
    path: "/reports/cash-transfers",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/reports/cash-transfers.vue")
  },
  {
    name: "reports-closing-errors",
    path: "/reports/closing-errors",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/reports/closing-errors.vue")
  },
  {
    name: "reports-download",
    path: "/reports/download",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/reports/download.vue")
  },
  {
    name: "reports-efficiency",
    path: "/reports/efficiency",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/reports/efficiency.vue")
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/reports/index.vue")
  },
  {
    name: "reports-payments",
    path: "/reports/payments",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/reports/payments.vue")
  },
  {
    name: "reports-production",
    path: "/reports/production",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/reports/production.vue")
  },
  {
    name: "reports-reservations",
    path: "/reports/reservations",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/reports/reservations.vue")
  },
  {
    name: "reports-reviews",
    path: "/reports/reviews",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/reports/reviews.vue")
  },
  {
    name: "reports-tips",
    path: "/reports/tips",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/reports/tips.vue")
  },
  {
    name: settings3hP7GC8sssMeta?.name,
    path: "/settings",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settings.vue"),
    children: [
  {
    name: "settings-accounts",
    path: "accounts",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settings/accounts.vue")
  },
  {
    name: "settings-finances",
    path: "finances",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settings/finances.vue")
  },
  {
    name: "settings",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settings/index.vue")
  },
  {
    name: "settings-integrations",
    path: "integrations",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settings/integrations.vue")
  },
  {
    name: "settings-operations",
    path: "operations",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settings/operations.vue")
  }
]
  },
  {
    name: _91id_93uvGUWLOXrYMeta?.name,
    path: "/settlement/channel-settlements/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/channel-settlements/[id].vue"),
    children: [
  {
    name: "settlement-channel-settlements-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/channel-settlements/[id]/index.vue")
  },
  {
    name: "settlement-channel-settlements-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/channel-settlements/[id]/log.vue")
  },
  {
    name: "settlement-channel-settlements-id-orders",
    path: "orders",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/channel-settlements/[id]/orders.vue")
  },
  {
    name: "settlement-channel-settlements-id-snapshots-channelSettlementSnapshotId",
    path: "snapshots/:channelSettlementSnapshotId()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue")
  },
  {
    name: "settlement-channel-settlements-id-snapshots",
    path: "snapshots",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/channel-settlements/[id]/snapshots/index.vue")
  }
]
  },
  {
    name: "settlement-orders-id",
    path: "/settlement/orders/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/orders/[id].vue")
  },
  {
    name: "settlement-orders",
    path: "/settlement/orders",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/orders/index.vue")
  },
  {
    name: _91id_93ANc41ai5d4Meta?.name,
    path: "/settlement/store-settlements/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/store-settlements/[id].vue"),
    children: [
  {
    name: "settlement-store-settlements-id-bookkeeping",
    path: "bookkeeping",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/store-settlements/[id]/bookkeeping.vue")
  },
  {
    name: "settlement-store-settlements-id-channel-settlements",
    path: "channel-settlements",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/store-settlements/[id]/channel-settlements.vue")
  },
  {
    name: "settlement-store-settlements-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/store-settlements/[id]/index.vue")
  },
  {
    name: "settlement-store-settlements-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/store-settlements/[id]/log.vue")
  },
  {
    name: "settlement-store-settlements-id-orders",
    path: "orders",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/store-settlements/[id]/orders.vue")
  },
  {
    name: "settlement-store-settlements-id-reconciliations-reconciliationId",
    path: "reconciliations/:reconciliationId()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/store-settlements/[id]/reconciliations/[reconciliationId].vue")
  },
  {
    name: "settlement-store-settlements-id-reconciliations",
    path: "reconciliations",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/store-settlements/[id]/reconciliations/index.vue")
  }
]
  },
  {
    name: "settlement-store-settlements",
    path: "/settlement/store-settlements",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20250127085832/pages/settlement/store-settlements/index.vue")
  }
]