import { defineStore } from "pinia";
import { useApi } from "~/composables/useApi.js";

export const useMerchant = defineStore("merchant", {
  state: () => ({
    merchant: null,
  }),

  actions: {
    async load() {
      const response = await useApi("/api/v1/merchant/merchant");

      this.merchant = response.data;
    },

    async update(data) {
      const response = await useApi("/api/v1/merchant/merchant", {
        method: "PUT",
        body: data,
      });

      this.merchant = response.data;
    },

    async updateBookkeepingConfiguration(data) {
      const response = await useApi(
        "/api/v1/merchant/bookkeeping-configuration",
        {
          method: "PUT",
          body: data,
        },
      );

      this.merchant.attributes.has_bookkeeping_configuration = true;

      this.merchant.attributes.bookkeeping_strategy =
        response.data.attributes.strategy;

      return response.data;
    },

    async destroyBookkeepingConfiguration() {
      await useApi("/api/v1/merchant/bookkeeping-configuration", {
        method: "DELETE",
      });

      this.merchant.attributes.has_bookkeeping_configuration = false;

      this.merchant.attributes.bookkeeping_strategy = null;
    },

    async updateStockConfiguration(data) {
      const response = await useApi("/api/v1/merchant/stock-configuration", {
        method: "PUT",
        body: data,
      });

      this.merchant.attributes.has_stock_configuration = true;

      return response.data;
    },

    async destroyStockConfiguration() {
      await useApi("/api/v1/merchant/stock-configuration", {
        method: "DELETE",
      });

      this.merchant.attributes.has_stock_configuration = false;
    },
  },

  getters: {
    bookkeepingStrategy: (state) =>
      state.merchant.attributes.bookkeeping_strategy,
    hasReservationConfiguration: (state) =>
      state.merchant.attributes.has_reservation_configuration,
    hasAccountConfigurations: (state) =>
      state.merchant.attributes.has_account_configurations,
    hasReviewConfigurations: (state) =>
      state.merchant.attributes.has_review_configurations,
    hasStockConfiguration: (state) =>
      state.merchant.attributes.has_stock_configuration,
    hasBookkeepingConfiguration: (state) =>
      state.merchant.attributes.has_bookkeeping_configuration,
  },
});
